import domReady from '@roots/sage/client/dom-ready';

import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'

/**
 * Application entrypoint
 */
domReady(async () => {
  
  // Alpine.js
  window.Alpine = Alpine;
  Alpine.plugin(collapse);
  Alpine.start();
  
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
